<template>
  <div class="cash-coupon-manager">
    <!-- 储值卡（提货券）发放 -->
    <x-dialog :proxy="dialogRef" class="grant">
      <el-tabs v-model="currentTab" @tab-click="tabChange">
        <el-tab-pane label="卡密管理">
          <en-table-layout :table-data="CarmiTable">
            <template slot="toolbar">
              <el-input style="width: 220px" size="medium" clearable v-model="name" placeholder="请输入卡号或卡密"></el-input>

              <el-button class="ml-3" @click="handleClickSousuo" type="primary" size="small">
                搜索
              </el-button>

              <div class="col"></div>

              <el-button @click="addCardPassword" size="small" type="primary">
                添加卡密
              </el-button>
            </template>

            <template slot="table-columns">
              <!-- 序号 -->
              <el-table-column label="序号" width="50">
                <template slot-scope="scope">
                  {{ scope.$index + 1 }}
                </template>
              </el-table-column>

              <el-table-column label="生成方式">

                <template slot-scope="scope">
                  {{ scope.row.create_type === 1 ? "系统生成" : "表格导入" }}
                </template>
              </el-table-column>

              <el-table-column label="添加时间" width="180">

                <template slot-scope="scope">
                  {{
      scope.row.create_time | unixToDate("yyyy-MM-dd hh:mm:ss")
    }}
                </template>
              </el-table-column>

              <el-table-column prop="key_num" label="总数" />

              <el-table-column prop="rest_total" label="总余额">

                <template slot-scope="scope">
                  {{ (scope.row.rest_total || 0) | unitPrice }}
                </template>
              </el-table-column>

              <el-table-column prop="active_num" label="已激活数" />

              <el-table-column prop="binding_num" label="已绑定数" />

              <!-- <el-table-column prop="exchange_num" label="已兑换数"></el-table-column> -->

              <el-table-column label="操作" width="80">

                <template slot-scope="scope">
                  <el-button size="mini" type="primary" @click="handleCarmiTableDetailrow(scope.row)">
                    查看
                  </el-button>
                </template>
              </el-table-column>
            </template>

            <el-pagination slot="pagination" @size-change="handleCarmiTableSizeChange"
              @current-change="handleCarmiTableCurrentChange" :current-page="CarmiParams.page_no"
              :page-sizes="MixinPageSizes" :layout="MixinTableLayout" background :page-size="CarmiParams.page_size"
              :total="CarmiParams.total">
            </el-pagination>
          </en-table-layout>
        </el-tab-pane>

        <el-tab-pane label="在线发放" lazy>
          <en-table-layout :table-data="grantList.data">

            <template slot="toolbar">
              <el-input style="width: 220px" size="medium" clearable v-model="name" placeholder="请输入卡号或卡密"></el-input>

              <el-button class="ml-3" @click="handleClickSousuo" type="primary" size="small">
                搜索
              </el-button>

              <div class="col"></div>

              <el-button @click="handleClickXinjian" size="small" type="primary">
                新建发放
              </el-button>
            </template>

            <template slot="table-columns">
              <el-table-column :key="'num'" label="序号" width="50">
                <template slot-scope="scope">
                  {{ scope.$index + 1 }}
                </template>
              </el-table-column>

              <el-table-column :key="'source'" label="发放名单来源">

                <template slot-scope="scope">
                  {{ scope.row.create_type === 1 ? "系统生成" : "表格导入" }}
                </template>
              </el-table-column>

              <el-table-column :key="'addtime'" label="添加时间">

                <template slot-scope="scope">
                  {{
      scope.row.create_time | unixToDate("yyyy-MM-dd hh:mm:ss")
    }}
                </template>
              </el-table-column>

              <el-table-column :key="'group_name'" prop="group_name" label="所属客户组"></el-table-column>

              <el-table-column :key="'key_num'" prop="key_num" label="份数"></el-table-column>

              <!-- active_num -->
              <el-table-column :key="'sum'" prop="sum" label="卡券总金额">
                <!-- exchange_num -->

                <template slot-scope="scope">
                  {{ (scope.row.sum || 0) | unitPrice }}
                </template>
              </el-table-column>

              <el-table-column :key="'rest_total'" prop="rest_total" label="卡券总余额">

                <template slot-scope="scope">
                  {{ scope.row.rest_total | unitPrice }}
                </template>
              </el-table-column>

              <el-table-column label="操作" width="80">

                <template slot-scope="scope">
                  <el-button @click="handleCarmiTableDetailrow(scope.row)" size="mini" type="primary">查看
                  </el-button>
                </template>
              </el-table-column>
            </template>

            <el-pagination slot="pagination" @size-change="handlegrantListSizeChange"
              @current-change="handlegrantListCurrentChange" :current-page="grantList.page_no"
              :page-sizes="MixinPageSizes" :layout="MixinTableLayout" background :page-size="grantList.page_size"
              :total="grantList.data_total">
            </el-pagination>
          </en-table-layout>
        </el-tab-pane>
      </el-tabs>
    </x-dialog>

    <!-- 卡密管理 -->
    <!-- 查看卡密 -->
    <x-dialog :proxy="viewCardDialogRef">
      <en-table-layout @selection-change="CarmiTableListSelectChange" :table-data="CarmiTableList.data"
        :row-key="(row) => row.id" ref="viewCardTable">

        <template slot="toolbar">
          <el-form-item label="状态">
            <el-select @change="handleSelectChange" clearable style="width: 100px" v-model="status" size="medium">
              <el-option v-for="item in CarmiTableListOptions" :key="item.value" :label="item.label"
                :value="item.value"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="卡号区间">
            <el-input style="width: 150px" v-model="cardIntervalList.card_code_start" placeholder="请输入开始卡号" clearable />
            —
            <el-input style="width: 150px" v-model="cardIntervalList.card_code_end" placeholder="请输入结束卡号" clearable />
            <div class="card-code-prompt-text" v-text="promptText"></div>
          </el-form-item>
          <el-form-item label="关键字">
            <el-input v-model="CarmiTableParams.search_value" placeholder="请输入关键字" size="medium" clearable>
              <el-select v-model="CarmiTableParams.search_type" placeholder="关键词类型" style="width: 90px" slot="prepend">
                <el-option label="卡号" :value="1"></el-option>
                <el-option label="手机号" :value="2"></el-option>
              </el-select>
            </el-input>
          </el-form-item>

          <el-button @click="() => handleCarmiTableDetail(false, true)" size="small" type="primary">
            搜索
          </el-button>

          <div class="col"></div>

          <div :style="{ marginTop: promptText ? '22px' : '12px', width: '100%' }">
            <el-button v-show="currentTab === '0'" @click="intervalToActivate(1)" type="primary" size="small">
              区间激活
            </el-button>
            <el-button v-show="currentTab === '0'" @click="intervalToActivate(0)" type="primary" size="small">
              区间禁用
            </el-button>
            <el-button @click="handleActivationCheck(0)" type="primary" size="small">
              激活选中
            </el-button>

            <el-button @click="handleActivationCheck(1)" type="primary" size="small">
              禁用选中
            </el-button>

            <el-button @click="handleActivationAll(0)" type="primary" size="small">
              激活全部
            </el-button>

            <el-button @click="handleActivationAll(1)" type="primary" size="small" :loading="cancelActivatedAllLoading">
              禁用全部
            </el-button>

            <el-button @click="handleClickRecord" type="primary" size="small">
              操作日志
            </el-button>
            <el-button v-show="currentTab === '1'" @click="handleActivationCheck(3)" type="primary" size="small">
              延期选中
            </el-button>
            <el-button size="small" @click="ExportGetrecordList1()" type="primary" :loading="exportAllLoading">
              导出全部
            </el-button>
          </div>

          <span v-if="exportAllLoading">提示：正在导出<span id="spinner">...</span></span>
        </template>

        <template slot="table-columns">
          <el-table-column :key="'selection'" :reserve-selection="true" type="selection" width="50" />
          <!-- 序号 卡号 卡密 绑定用户 储值卡余额 状态 业务员 操作 -->
          <!-- 编号 卡号 卡密 套餐礼包 兑换时间 状态 所属销售公司 业务员 操作-->
          <!-- 1  卡密    2  发放  -->
          <el-table-column :key="'code'" label="编号" width="60">
            <template slot-scope="scope">
              {{ scope.$index + 1 }}
            </template>
          </el-table-column>

          <el-table-column :key="'card_code'" prop="card_code" label="卡号" min-width="70"></el-table-column>

          <el-table-column :key="'video_url'" prop="video_url" label="关联链接" width="150"
            v-if="showLink"></el-table-column>

          <el-table-column :key="'card_key'" prop="card_key" label="卡密" min-width="70"></el-table-column>

          <el-table-column :key="'binding_member_mobile'" prop="binding_member_mobile" label="所绑用户手机号" width="120">
            <!-- v-if="falg === 2" -->
          </el-table-column>
          <el-table-column :key="'left_value'" prop="left_value" label="储值卡余额" width="120">
            <!-- v-if="falg === 2" -->
            <!--          备注：修改线上bug，不清楚组件是否复用，暂时先做这样的判断  -->

            <template slot-scope="scope">
              <span v-if="scope.row.left_value">
                {{ scope.row.left_value | unitPrice }}
              </span>
              <span v-else-if="scope.row.left_value === 0">
                {{ scope.row.left_value | unitPrice }}
              </span>
              <span v-else>
                {{
      scope.row.left_value ? scope.row.left_value : "" | unitPrice
    }}
              </span>
            </template>
          </el-table-column>

          <!-- <el-table-column
            prop="binding_time"
            label="绑定时间"
            v-if="falg === 2"
          >
            <template slot-scope="scope">
              {{ scope.row.binding_time | unixToDate("yyyy-MM-dd hh:mm") }}
            </template>
          </el-table-column> -->

          <el-table-column :key="'statuses'" prop="statuses" label="状态" width="150">

            <template slot-scope="scope">
              <!--  1：已兑换  2：已绑定   3：已激活  , 0 未激活  ,4 已禁用 -->
              <!-- 1  已激活    2 未激活    3 已绑定    4 禁用中  -->
              <!-- 企业关卡状态:
                0未被企业关卡单占用
                1企业关卡单占用
                2企业已关卡
                3企业开卡单占用
                4企业已开卡 -->
              {{
      scope.row.statuses === 2 && scope.row.close_status === 2
        ? "禁用（企业关卡）"
        : scope.row.close_status === 2
          ? "禁用（企业关卡）"
          : scope.row.close_status === 3
            ? "禁用（企业关卡）"
            : scope.row.close_status === 4
              ? "激活（企业开卡）"
              : scope.row.statuses === 1
                ? "已激活"
                : scope.row.statuses === 2
                  ? "未激活"
                  : scope.row.statuses === 3
                    ? "已绑定"
                    : scope.row.statuses === 4
                      ? "禁用中"
                      : ""
    }}
            </template>
          </el-table-column>

          <el-table-column prop="company" label="所属销售公司" width="120">

            <template slot-scope="scope">
              <el-tooltip v-if="scope.row.company" class="item" effect="dark" :content="scope.row.company"
                placement="top">
                <span class="tooltip-text-32" v-text="scope.row.company"></span>
              </el-tooltip>
              <span v-else>————</span>
            </template>
          </el-table-column>

          <el-table-column prop="active_salesman" label="业务员" width="120">

            <template slot-scope="scope">
              <el-tooltip v-if="scope.row.active_salesman" class="item" effect="dark"
                :content="scope.row.active_salesman" placement="top">
                <span v-text="scope.row.active_salesman" class="tooltip-text-32"></span>
              </el-tooltip>
              <span v-else>————</span>
            </template>
          </el-table-column>

          <!-- <el-table-column
            prop="active_name"
            label="激活人"
          ></el-table-column> -->
          <el-table-column label="操作" v-if="currentTab === '0'" min-width="100">

            <template slot-scope="scope">
              <el-button v-if="scope.row.statuses === 2 || scope.row.statuses === 4" size="mini" type="primary"
                @click="handleCarmiTableListActivation(scope.row, 0)">{{ (scope.row.close_status === 2 ||
      scope.row.close_status === 3)
      ? "启用"
      : scope.row.statuses === 2
        ? "激活"
        : "启用"
                }}
              </el-button>
              <!-- 1  已激活：取消激活    2 未激活：激活   3 已绑定:禁用     4 禁用中:启用  -->
              <el-button v-if="scope.row.statuses === 1 || scope.row.statuses === 3" size="mini" type="primary"
                @click="handleCarmiTableListActivation(scope.row, 1)">
                {{
      (scope.row.close_status === 2 || scope.row.close_status === 3)
        ? "禁用"
        : scope.row.statuses === 1
          ? "取消激活"
          : "禁用"
    }}
              </el-button>
              <el-button size="mini" type="primary" @click="handleCarmiTableListActivation(scope.row, 'postpone')"
                v-if="scope.row.statuses !== 2">
                延期
              </el-button>
            </template>
          </el-table-column>
        </template>

        <el-pagination slot="pagination" @size-change="handleCarmiTableListSizeChange"
          @current-change="handleCarmiTableListCurrentChange" :page-size="CarmiTableList.page_size"
          :current-page="CarmiTableList.page_no" :layout="MixinTableLayout" :page-sizes="MixinPageSizes" background
          :total="CarmiTableList.data_total">
        </el-pagination>
      </en-table-layout>
    </x-dialog>
    <!-- 延期操作 -->
    <x-dialog :proxy="postpone">
      <div class="addMealForm">
        <el-form :model="postponeVal" ref="postpone" label-width="110px" :rules="postponeRules">
          <el-form-item class="w200" prop="num" label="延期时间">
            <el-input-number :min="1" :max="5000" style="width: 200px" :controls="false" v-model="postponeVal.num"
              placeholder="请输入延期天数"></el-input-number>天
          </el-form-item>
          <div class="tipBox" style="padding-left: 40px;">
            <p>提示：要延长的时间将会从设置完保存的那一刻开始计算。</p>
          </div>
        </el-form>
      </div>
    </x-dialog>
    <!-- 添加卡密 -->
    <x-dialog :proxy="addCardPasswordDialog">
      <el-tabs v-if="addCardPasswordDialog.visible" v-model="createCarmiType" type="card">
        <el-tab-pane label="系统生成卡密" name="1">
          <div class="tipBox">
            <p>*输入生成数量，即可一键生成卡密</p>
            <p>*导入的卡密默认为未激活状态</p>
          </div>
          <div class="addMealForm">
            <el-form :model="submitCreateCardKeysParams" :rules="submitCreateCardKeysRules"
              ref="submitCreateCardKeysParams" label-width="110px">
              <el-form-item class="w200" label="生成数量" prop="num">
                <el-input-number :min="1" style="width: 200px" :controls="false"
                  v-model="submitCreateCardKeysParams.num" placeholder="请输入1-5000的整数">
                </el-input-number>
                张
              </el-form-item>
              <el-form-item label="卡号前缀" prop="prefix_code">
                <el-input style="width: 200px" placeholder="请输入5位以内的字母"
                  v-model="submitCreateCardKeysParams.prefix_code"></el-input>
              </el-form-item>
            </el-form>
          </div>
        </el-tab-pane>
        <el-tab-pane label="表格导入卡密" name="2">
          <div class="tipBox b1">
            <p>*您可导入卡号+卡密或仅卡密</p>
            <p>*卡号和卡密都不可重复</p>
            <p>*导入的卡密默认为未激活状态</p>
            <p>
              *卡号由5位以内纯字母的卡号前缀（可不填）+3位及以上数字部分组成，卡号数字部分不能以0开头；卡密由6位及以上的数字、字母组成。所有字母均区分大小写。
            </p>
          </div>
          <div class="shep">第一步：根据需求，勾选下列内容，形成表格模板</div>
          <el-radio-group v-model="downLoadType">
            <el-radio :label="0">仅卡密</el-radio>
            <el-radio :label="1">卡号+卡密</el-radio>
          </el-radio-group>
          <br />
          <el-button style="margin-top: 10px; margin-bottom: 15px" type="default" size="small"
            @click="downTemplate">下载表格模板
          </el-button>
          <div class="shep">
            第二步：编辑表格，请不要修改/删除表格中的表头文字，且不要修改表格的格式
          </div>
          <div class="shep">第三步：导入表格</div>
          <div class="add-camilo-file-box">
            <upload-excel-component defaultSize :on-success-tem="excelSuccess" @upFileName="(e) => {
      this.tooltipContent = e;
    }
      " />
            <el-tooltip v-if="tooltipContent" class="item" effect="dark" :content="tooltipContent"
              placement="top-start">
              <el-button class="add-camilo-file" v-text="tooltipContent"></el-button>
            </el-tooltip>
          </div>
        </el-tab-pane>
      </el-tabs>
    </x-dialog>

    <!-- 在线发放log日志 -->
    <!-- 添加发放 -->
    <x-dialog :proxy="publishOnlineDialog">
      <el-tabs v-if="publishOnlineDialog.visible" v-model="createGrantType" type="card">
        <el-tab-pane label="发放给系统用户" name="1">
          <div class="row no-gutters align-items-center mb-3">
            <div class="col-auto mr-3">
              <el-input placeholder="请输入关键字搜索" v-model="createGrantParams.keyword" class="input-with-select">
                <el-button @click="getMemberList" slot="append" icon="el-icon-search"></el-button>
              </el-input>
            </div>
            <div class="col-auto">
              <el-select @change="changeUserList" v-model="createGrantParams.group_id" placeholder="请选择用户分组" clearable>
                <el-option v-for="item in UserOptions" :key="item.group_id" :label="item.group_name"
                  :value="item.group_id"></el-option>
              </el-select>
            </div>
          </div>
          <div class="tables">
            <el-table ref="multipleTable" :row-key="(row) => row.member_id"
              @selection-change="createGrantTableSelectChange" :data="createGranList.data" border :header-cell-style="{
      textAlign: 'center',
      backgroundColor: 'rgba(230, 236, 247, 1)',
    }" :cell-style="headClass" style="width: 100%">
              <el-table-column :key="'slct'" type="selection" :reserve-selection="true" width="55"></el-table-column>
              <el-table-column :key="'nickname'" prop="nickname" label="用户姓名"></el-table-column>
              <el-table-column :key="'g_name'" prop="group_name" label="所属分组">

                <template slot-scope="scope">
                  {{ scope.row.group_name ? scope.row.group_name : "——" }}
                </template>
              </el-table-column>
              <el-table-column :key="'mob'" prop="mobile" label="手机号"></el-table-column>
            </el-table>
          </div>
          <div style="text-align: center; margin-top: 20px" class="pages">
            <el-pagination @size-change="handlecreateGrantSizeChange" @current-change="handlecreateGrantCurrentChange"
              :current-page="createGranList.page_no" :page-sizes="MixinPageSizes" :page-size="createGranList.page_size"
              :layout="MixinTableLayout" :total="createGranList.data_total" background>
            </el-pagination>
          </div>
          <div style="
              height: 40px;
              display: flex;
              align-items: center;
              margin-top: 10px;
            ">
            <el-checkbox v-model="activationData.checked" style="margin: 0">是否直接激活</el-checkbox>
            <el-form v-if="activationData.checked" :model="activationData" :rules="activationForm" ref="activationRef"
              label-width="125px" class="demo-ruleForm">
              <el-form-item label="业务员名称" prop="active_salesman" style="width: 300px; display: inline-block; margin: 0">
                <el-input v-model="activationData.active_salesman" size="small"></el-input>
              </el-form-item>
              <el-form-item label="所属销售公司" prop="company" style="width: 300px; display: inline-block; margin: 0">
                <el-input v-model="activationData.company" size="small"></el-input>
              </el-form-item>
            </el-form>
          </div>
          <div>
            <el-checkbox v-model="checked" @change="handleCheckboxChange">短信通知
            </el-checkbox>
            <p>
              【短信签名】发券通知：尊敬的客户，您收到一张提货券，请您在微信端打开链接[链接地址]并使用收到此条短信的手机号验证登录，
              即日起2年有效期，如有疑问，请联系客服[客服电话]。
            </p>
          </div>
        </el-tab-pane>
        <el-tab-pane label="表格导入发放名单" name="2">
          <div class="tipBox b2">
            <p>
              *您可根据客户的需求，导入不同格式的数据，如手机号、分组名称、姓名
            </p>
            <p>*导入的客户数据的分组必须是当前商城中已有的分组</p>
            <!-- <p>*姓名和分组可以为空，手机号不能为空</p> -->
          </div>
          <div class="shep">第一步：下载表格模板</div>
          <el-radio-group v-model="downLoadType1">
            <el-radio :label="0">导入手机号</el-radio>
          </el-radio-group>
          <br />
          <el-button style="margin-top: 10px; margin-bottom: 15px" type="default" @click="downTemplate1">下载表格模板
          </el-button>
          <div class="shep">
            第二步：编辑表格，请不要修改/删除表格中的表头文字，且不要修改表格的格式
          </div>
          <div class="shep">第三步：导入表格</div>
          <div class="add-camilo-file-box">
            <upload-excel-component defaultSize :on-success-tem="excelSuccess1" @upFileName="(e) => {
      this.tooltipContentTwo = e;
    }
      " />
            <el-tooltip v-if="tooltipContentTwo" class="item" effect="dark" :content="tooltipContentTwo"
              placement="top-start">
              <el-button class="add-camilo-file" v-text="tooltipContentTwo"></el-button>
            </el-tooltip>
          </div>
          <div style="
              height: 40px;
              display: flex;
              align-items: center;
              margin-top: 10px;
            ">
            <el-checkbox v-model="activationData.checked" style="margin: 0">是否直接激活</el-checkbox>
            <el-form v-if="activationData.checked" :model="activationData" :rules="activationForm" ref="activationRef"
              label-width="125px" class="demo-ruleForm">
              <el-form-item label="业务员名称" prop="active_salesman" style="width: 300px; display: inline-block; margin: 0">
                <el-input v-model="activationData.active_salesman" size="small"></el-input>
              </el-form-item>
              <el-form-item label="所属销售公司" prop="company" style="width: 300px; display: inline-block; margin: 0">
                <el-input v-model="activationData.company" size="small"></el-input>
              </el-form-item>
            </el-form>
          </div>
          <div style="margin-top: 10px">
            <el-checkbox v-model="checked" @change="handleCheckboxChange">短信通知
            </el-checkbox>
            <p>
              【短信签名】发券通知：尊敬的客户，您收到一张储值卡券，请您前往
              [链接] 进行使用，如有疑问请联系客服。
            </p>
          </div>
        </el-tab-pane>
      </el-tabs>
    </x-dialog>

    <!-- 操作记录 -->
    <el-dialog title="操作记录" :visible.sync="recordFlag" width="80%" class="popinfo" append-to-body>
      <en-table-layout :tableData="recordTableData.data" :loading="loading" style="margin: -30px -20px">

        <template slot="toolbar">
          <el-form-item label="操作类型">
            <el-select size="medium" v-model="recordinform.oper_type" clearable
              style="width: 150px !important; margin-left: 10px">
              <el-option label="全部" value></el-option>
              <el-option label="激活" value="2"></el-option>
              <el-option label="禁用" value="1"></el-option>
              <el-option label="延期" value="3"></el-option>
            </el-select>
          </el-form-item>

          <el-form-item label="关键词">
            <el-input v-model="recordinform.key_word" size="medium" style="width: 220px" clearable
              placeholder="请输入卡号/卡密" />
          </el-form-item>

          <el-button size="small" type="primary" @click="getrecordList">
            搜索
          </el-button>

          <div class="col"></div>

          <el-button :loading="exportAllIssueLoading" size="small" type="primary" @click="ExportGetrecordList2">
            导出全部
          </el-button>
        </template>

        <template slot="table-columns">
          <el-table-column :key="'serial_no'" label="编号">
            <template slot-scope="scope">{{ scope.$index + 1 }}</template>
          </el-table-column>
          <el-table-column :key="'opt_time'" prop="goods_name" label="操作时间">

            <template slot-scope="scope">
              {{ scope.row.create_time | unixToDate("yyyy-MM-dd hh:mm:ss") }}
            </template>
          </el-table-column>
          <el-table-column :key="'oper_type'" prop="oper_type" label="操作类型">

            <template slot-scope="scope">
              <span>{{ operTypefilter(scope.row.oper_type) }}</span>
              <span v-if="scope.row.is_enterprise == 1">
                {{ scope.row.oper_type === 1 ? "（企业关卡）" : "（企业开卡)" }}
              </span>
            </template>
          </el-table-column>
          <el-table-column :key="'cd_code'" prop="card_code" label="卡号" />
          <el-table-column :key="'cd_key'" prop="card_key" label="卡密" />
          <el-table-column :key="'opr_name'" prop="opr_name" label="操作人" />
          <el-table-column :key="'ip'" prop="ip" label="ip地址" />
        </template>
        <el-pagination slot="pagination" v-if="recordTableData" @size-change="handlePageSizeChange1"
          @current-change="handlePageCurrentChange1" :current-page="recordTableData.page_no"
          :page-sizes="MixinPageSizes" :layout="MixinTableLayout" :page-size="recordTableData.page_size"
          layout="total, sizes, prev, pager, next, jumper" :total="recordTableData.data_total"></el-pagination>
      </en-table-layout>
    </el-dialog>

    <!-- 激活信息填写 -->
    <x-dialog :proxy="fillInActivationInformation">
      <el-form :model="jihuoFromData" :rules="jihuoFromDatarules" ref="ruleForm" label-width="120px"
        class="demo-ruleForm">
        <el-form-item label="业务员名称" prop="active_salesman">
          <el-input v-model="jihuoFromData.active_salesman"></el-input>
        </el-form-item>
        <el-form-item label="所属销售公司" prop="company">
          <el-input v-model="jihuoFromData.company"></el-input>
        </el-form-item>
      </el-form>
    </x-dialog>
    <!-- 区间激活/禁用填写 （intervalType：1 激活，0 禁用）-->
    <x-dialog :proxy="intervalType ? intervalActivationInformation : intervalDisable">
      <div style="padding: 10px 12px 5px">
        {{
      intervalType
        ? "请填写激活信息及要激活的卡号区间："
        : "请填写要禁用的卡号区间："
    }}
      </div>
      <div style="padding: 0 12px 10px; font-size: 12px; color: #aeacac">
        {{
        intervalType
          ? "提示：区间激活的时候，只激活区间内未激活和被禁用的卡号。"
          : "提示：区间禁用的时候，只禁用区间内已激活和已绑定的卡号。"
      }}
      </div>
      <el-form :model="intervalFromData" :rules="intervalFromDatarules" ref="intervalRef" label-width="120px"
        class="demo-ruleForm" style="padding-right: 10px">
        <el-form-item label="业务员名称" prop="active_salesman" v-if="intervalType">
          <el-input v-model="intervalFromData.active_salesman"></el-input>
        </el-form-item>
        <el-form-item label="所属销售公司" prop="company" v-if="intervalType">
          <el-input v-model="intervalFromData.company"></el-input>
        </el-form-item>
        <el-form-item label="卡号区间" style="max-height: 200px; overflow-y: auto">
          <div v-for="(item, index) in intervalFromData.cardList" style="position: relative; margin-bottom: 20px"
            :key="index">
            <el-input style="width: 145px" v-model="item.card_code_start" placeholder="请输入开始卡号"
              @input="dialogCardHandle(item.card_code_start, index, 'start')" clearable />
            —
            <el-input style="width: 145px" v-model="item.card_code_end" placeholder="请输入结束卡号"
              @input="dialogCardHandle(item.card_code_end, index, 'end')" clearable />
            <div class="card-code-prompt-text" v-text="item.dialogPromptText"></div>
            <el-button v-if="index === 0" size="small" type="primary" class="dialog-card-code"
              @click="dialogCardBut('add', index)">
              新增区间
            </el-button>
            <el-button v-else size="small" type="primary" class="dialog-card-code"
              @click="dialogCardBut('remove', index)">
              删除
            </el-button>
          </div>
        </el-form-item>
      </el-form>
    </x-dialog>
  </div>
</template>

<script>
import * as API_HotGoods from "@/api/hotGoods";
import * as API_coupon from "@/api/coupon";
import * as API_Card from "@/api/combo-card-shop/card";
import UploadExcelComponent from "@/components/UploadExcel";
import { handleDownload, selfDefinedTemplate } from "@/utils";
import { UE } from "@/components";
import { Foundation } from "@/../ui-utils";
import EnTableLayout from "../../../../../ui-components/TableLayout/src/main";
import XDialog from "@/components/x-dialog/x-dialog";
import { $xDialog } from "@/components/x-dialog/dialog.proxy";

export default {
  name: "grant",
  components: {
    XDialog,
    EnTableLayout,
    [UE.name]: UE,
    UploadExcelComponent,
  },
  props: {
    yfather: Number,
  },
  data () {
    return {
      tooltipContent: "",
      tooltipContentTwo: "",
      fillInActivationInformation: $xDialog.create({
        title: "激活信息填写",
        width: "30vw",
        beforeConfirm: () => this.handlejihuoFormData("ruleForm"),
        beforeCancel: () => this.resetForm("ruleForm"),
      }),
      intervalActivationInformation: $xDialog.create({
        title: "区间激活填写",
        width: "42vw",
        beforeConfirm: () => this.handleIntervalActivation("intervalRef"),
      }),
      intervalDisable: $xDialog.create({
        title: "区间禁用",
        width: "42vw",
        beforeConfirm: () => this.handleIntervalDisable(),
      }),
      dialogRef: $xDialog.create({
        title: "储值卡管理",
        width: "95vw",
        disableCancel: true,
        disableConfirm: true,
        wrapperClass: "cash-coupon-manager-main-dialog",
        afterDismiss: () => this.reset(),
      }),
      viewCardDialogRef: $xDialog.create({
        title: "卡密详情",
        width: "95vw",
        disableCancel: true,
        disableConfirm: true,
        wrapperClass: "view-card-dialog",
        afterDismiss: () => {
          this.CarmiTableList = { data: [] };
          this.CarmiParams = {
            card_id: null,
            page_size: 20,
            page_no: 1,
            total: 0,
          };
        },
      }),
      postpone: $xDialog.create({
        title: "延期操作",
        width: "600px",
        wrapperClass: "addCardPasswordDialog",
        beforeConfirm: this.postponeFn,
        afterDismiss: () => this.postponeVal.num = ''
      }),
      // 延期天数
      postponeVal: {
        num: ""
      },
      // 延期操作校验规则
      postponeRules: {
        num: [
          { required: true, message: '请输入延期天数', trigger: 'blur' },
          { type: 'number', message: '请输入数字', trigger: 'blur' },
          {
            validator: (rule, value, callback) => {
              // 正整数校验规则
              if (!/^[1-9]\d*$/.test(value)) {
                callback(new Error('延期天数只允许是正整数'))
              } else {
                callback()
              }
            }, trigger: 'blur'
          }
        ]
      },
      currentTab: "0",
      loading: false,
      id: null,
      CarmiParams: {
        card_id: null,
        page_size: 20,
        page_no: 1,
        total: 0,
      },
      CarmiTable: [],
      intervalType: 1,
      isCarmiTableList: false,
      CarmiTableParams: {
        log_id: "",
        provide_log_id: "",
        card_id: "",
        card_code: "",
        active_status: "",
        search_type: 1,
        search_value: "",
        kcode: "",
        binding_member_mobile: "",
        page_size: 20,
        page_no: 1,
        total: 0,
      },
      cardIntervalList: {
        card_code_start: "",
        card_code_end: "",
      },
      status: "",
      promptText: "",
      dialogPromptText: "",
      CarmiTableList: { data: [] },
      CarmiTableListChange: [],
      // <!-- 1  已激活    2 未激活    3 已绑定    4 禁用中  -->
      CarmiTableListOptions: [],
      addCardPasswordDialog: $xDialog.create({
        title: "生成卡密",
        width: "600px",
        wrapperClass: "addCardPasswordDialog",
        beforeConfirm: () => {
          return new Promise((resolve) => {
            if (this.createCarmiType === "1") {
              this.submitCreateCardKeys().then(() => resolve(true));
            } else {
              this.submitImportCardKeys().then(() => resolve(true));
            }
          });
        },
      }),
      createCarmi: false,
      createCarmiType: "1",
      submitCreateCardKeysParams: {
        card_id: "",
        card_name: "",
        shop_name: "",
        num: "",
        prefix: "",
      },
      submitCreateCardKeysRules: {
        num: [
          { required: true, message: "请输入生成数量", trigger: "blur" },
          {
            required: true,
            validator: (rules, value, callback) => {
              if (value > 5000) {
                callback(new Error("请输入1-5000的整数"));
              } else if (value.toString().indexOf(".") !== -1) {
                callback(new Error("请输入1-5000的整数"));
              } else {
                callback();
              }
            },
            trigger: "blur",
          },
        ],
      },
      downLoadType: 0,
      submitImportCardKeysParams: {
        card_id: "",
        card_name: "",
        shop_name: "",
        card_value: "0",
        cards_key_dolist: [],
        prefix: "",
      },

      grantList: {},
      grantListParams: {
        card_id: "",
        page_size: 20,
        page_no: 1,
        total: 0,
      },
      publishOnlineDialog: $xDialog.create({
        title: "在线发放",
        width: "70vw",
        beforeConfirm: () => {
          return new Promise((resolve) => {
            if (this.createGrantType === "1") {
              this.submitCreateGrant("activationRef").then(() => resolve(true));
            } else {
              this.submitCreateGrant1("activationRef").then(() =>
                resolve(true)
              );
            }
          });
        },
      }),
      createGrantType: "1",
      createGrantParams: {
        // shop_id: this.$store.getters.shopInfo.shop_id, // 店铺
        page_size: 20,
        page_no: 1,
        keyword: "",
        group_id: "", // 分组id
      },
      createGranList: {},
      createGranSelect: [],
      UserOptions: [],
      createGrantDielog: false,
      submitCreateGrantParams: {
        card_id: "", // 卡券id
        member_ids: [], // 用户id
        member_names: [], // 用户姓名集合
        phones: [], // 用户手机号集合
        group_id: "", //用户组id
        send_message: 0, // 是否发送短信   1发  0不发
      },
      checked: false,
      activationData: {
        checked: false,
        active_salesman: "",
        company: "",
      },
      downLoadType1: 0,
      cardMemberVO: {
        card_id: "",
        member_list: {},
        send_message: 0, // 是否发送短信   1发  0不发
      },
      // 查看操作记录
      recordFlag: false,
      recordTableData: "",
      recordinform: {
        page_size: 20,
        page_no: 1,
        total: 0,
        oper_type: "",
        key_word: "",
        log_id: "", // 生成记录
        provide_log_id: "", // 发放记录
      },
      // 记录是单个激活 还是激活全部
      jihuoFromDataAll: false,
      // 记录是否为激活选中
      jihuoFromDataRowVla: false,
      // 激活时  填写的表单数据
      jihuoFromDataFlag: false,
      jihuoFromData: {
        active_salesman: "", // 操作业务员
        company: "", // 所属销售公司
      },
      intervalFromData: {
        active_salesman: "", // 操作业务员
        company: "", // 所属销售公司
        cardList: [
          {
            card_code_start: "",
            card_code_end: "",
            dialogPromptText: "",
          },
        ],
      },
      jihuoFromRow: "",
      jihuoFromnum: "",
      jihuoFromDatarules: {
        active_salesman: [
          {
            required: true,
            message: "请输入操作业务员",
            trigger: "blur",
          },
          { max: 32, message: "长度不能超过 32 个字符" },
        ],
        company: [
          {
            required: true,
            message: "请输入所属销售公司",
            trigger: "blur",
          },
          { max: 32, message: "长度不能超过 32 个字符" },
        ],
      },
      activationForm: {
        active_salesman: [
          {
            required: true,
            message: "请输入操作业务员",
            trigger: "blur",
          },
          { max: 32, message: "长度不能超过 32 个字符" },
        ],
        company: [
          {
            required: true,
            message: "请输入所属销售公司",
            trigger: "blur",
          },
          { max: 32, message: "长度不能超过 32 个字符" },
        ],
      },
      intervalFromDatarules: {
        active_salesman: [
          {
            required: true,
            message: "请输入操作业务员",
            trigger: "blur",
          },
          { max: 32, message: "长度不能超过 32 个字符" },
        ],
        company: [
          {
            required: true,
            message: "请输入所属销售公司",
            trigger: "blur",
          },
          { max: 32, message: "长度不能超过 32 个字符" },
        ],
      },
      falg: 1, // 记录是点击的卡密还是发放   1 卡密   2  发放
      rowData: {}, // 记录行数据
      name: "", //搜索框数据
      exportAllLoading: false,
      exportAllIssueLoading: false,
      cancelActivatedAllLoading: false,
      showLink: false,
    };
  },
  watch: {
    "cardIntervalList.card_code_start": {
      handler (newValue) {
        if (newValue) {
          this.status = "";
          this.handleSelectChange("");
          this.CarmiTableParams.search_value = "";
          this.cardIntervalList.card_code_start = this.regHandle(newValue);
        }
        if (newValue || this.cardIntervalList.card_code_end) {
          this.checkHandle(newValue, this.cardIntervalList.card_code_end);
        } else this.promptText = "";
      },
    },
    "cardIntervalList.card_code_end": {
      handler (newValue) {
        if (newValue) {
          this.status = "";
          this.handleSelectChange("");
          this.CarmiTableParams.search_value = "";
          this.cardIntervalList.card_code_end = this.regHandle(newValue);
        }
        if (newValue || this.cardIntervalList.card_code_start) {
          this.checkHandle(this.cardIntervalList.card_code_start, newValue);
        } else this.promptText = "";
      },
    },
    "CarmiTableParams.search_value": {
      handler (newValue) {
        if (newValue) {
          this.cardIntervalList.card_code_start = "";
          this.cardIntervalList.card_code_end = "";
        }
      },
    },
    currentTab: {
      handler (newValue) {
        if (newValue === "1") {
          this.CarmiTableListOptions = [
            {
              value: "3",
              label: "已绑定",
            },
            {
              value: "4",
              label: "禁用中",
            },
          ];
        } else {
          this.CarmiTableListOptions = [
            {
              value: "1",
              label: "已激活",
            },
            {
              value: "2",
              label: "未激活",
            },
            {
              value: "3",
              label: "已绑定",
            },
            {
              value: "4",
              label: "禁用中",
            },
          ];
        }
      },
      immediate: true,
    },
  },
  created () {
    this.MixinSetShopExtAuthList().then((res) => {
      if (res.toHiddenAuth.includes("shareVideoList")) {
        this.showLink = false;
      } else {
        this.showLink = true;
      }
    });
  },
  mounted () {
    if (this.yfather) {
      this.id = this.yfather;
      this.btnType = 0;
    }
    this.getUserlist();
  },
  methods: {
    regHandle (data) {
      const reg = new RegExp(/^[0-9a-zA-Z]/);
      let reData = "";
      for (let i = 0; i < data.length; i++) {
        if (reg.test(data[i])) reData += data[i];
      }
      return reData;
    },
    /**
     * 校验区间卡号
     * @param start 开始区间
     * @param end 结束区间
     * @param type 检验类型
     */
    checkHandle (start, end, type = "default") {
      let startNaN = 0;
      let endNaN = 0;
      let textContent = "";
      if (start && end) {
        for (let i = 0; i < (start.length >= 5 ? 5 : start.length); i++) {
          if (!(parseInt(start[i]) >= 0)) startNaN++;
          else break;
        }
        for (let i = 0; i < (end.length >= 5 ? 5 : end.length); i++) {
          if (!(parseInt(end[i]) >= 0)) endNaN++;
          else break;
        }
        if (startNaN != endNaN) textContent = "起始卡号与结束卡号前缀不一致";
        else if (start.substring(0, startNaN) !== end.substring(0, endNaN))
          textContent = "起始卡号与结束卡号前缀不一致";
        else if (
          parseInt(start.substring(startNaN)) != start.substring(startNaN)
        )
          textContent = "起始卡号格式错误";
        else if (parseInt(end.substring(endNaN)) != end.substring(endNaN))
          textContent = "结束卡号格式错误";
        else if (
          type === "default" &&
          parseInt(start.substring(startNaN)) >= parseInt(end.substring(endNaN))
        ) {
          textContent = "起始卡号数字编号需小于结束卡号";
        }
      } else if (!start || !end) textContent = "卡号区间起始框、结束框不能为空";
      type === "default"
        ? (this.promptText = textContent)
        : (this.intervalFromData.cardList[type].dialogPromptText = textContent);
    },
    /**
     * 监听弹窗区间卡号变化
     * @param data
     * @param index
     * @param type
     */
    dialogCardHandle (data, index, type) {
      if (type === "start") {
        if (data)
          this.intervalFromData.cardList[index].card_code_start =
            this.regHandle(data);
        if (data || this.intervalFromData.cardList[index].card_code_end) {
          this.checkHandle(
            data,
            this.intervalFromData.cardList[index].card_code_end,
            index
          );
        } else this.intervalFromData.cardList[index].dialogPromptText = "";
      } else {
        if (data)
          this.intervalFromData.cardList[index].card_code_end =
            this.regHandle(data);
        if (data || this.intervalFromData.cardList[index].card_code_start) {
          this.checkHandle(
            this.intervalFromData.cardList[index].card_code_start,
            data,
            index
          );
        } else this.intervalFromData.cardList[index].dialogPromptText = "";
      }
    },
    dialogCardBut (type, index) {
      if (type === "add") {
        this.intervalFromData.cardList.push({
          card_code_start: "",
          card_code_end: "",
          dialogPromptText: "",
        });
      } else {
        this.intervalFromData.cardList.splice(index, 1);
      }
    },
    addCardPassword () {
      this.addCardPasswordDialog.display();
    },
    show (row) {
      this.id = row.id;
      this.dialogRef.display({
        title: `卡券管理——${row.card_name}`,
      });
      this.tabChange();
    },
    reset () {
      this.currentTab = "0";

      this.CarmiParams = {
        card_id: null,
        page_size: 20,
        page_no: 1,
        total: 0,
      };
      this.CarmiTable = [];

      this.grantList = {};
      this.grantListParams = {
        card_id: "",
        page_size: 20,
        page_no: 1,
        total: 0,
      };
    },
    tabChange () {
      switch (this.currentTab) {
        case "0":
          // 卡密日志列表
          return this.getCardLogList();
        case "1":
          // 在线发放列表
          return this.getProvideLog();
      }
    },
    // 搜索按钮
    handleClickSousuo () {
      if (this.currentTab === "0") {
        this.CarmiParams.page_no = 1;
        this.CarmiParams.page_size = 10;
        this.getCardLogList(); // 卡密日志列表
      } else {
        this.grantListParams.page_no = 1;
        this.grantListParams.page_size = 10;
        this.getProvideLog(); // 在线发放列表
      }
    },
    // 是否发送短信多选框判断
    handleCheckboxChange () {
      if (this.checked) {
        this.submitCreateGrantParams.send_message = 1;
        this.cardMemberVO.send_message = 1;
      } else {
        this.submitCreateGrantParams.send_message = 0;
        this.cardMemberVO.send_message = 0;
      }
    },
    // 下拉框选择变化
    handleSelectChange (status) {
      if (status) {
        this.cardIntervalList.card_code_start = "";
        this.cardIntervalList.card_code_end = "";
      }
      status = parseInt(status);
      /*
        { value: "1", label: "已激活" },
        { value: "2", label: "未激活" },
        { value: "3", label: "已绑定" },
        { value: "4", label: "禁用中" },
        禁用中
        active_status=0
        binding_status=1

        已激活 active_status=1
        binding_status=0

        已绑定 active_status=1
        binding_status=1

        未激活 active_status=0
        binding_status=0
      */
      if (status === 1) {
        //已激活
        this.CarmiTableParams.active_status = 1;
        this.CarmiTableParams.binding_status = 0;
      } else if (status === 2) {
        //未激活
        this.CarmiTableParams.active_status = 0;
        this.CarmiTableParams.binding_status = 0;
      } else if (status === 3) {
        //已绑定
        this.CarmiTableParams.active_status = 1;
        this.CarmiTableParams.binding_status = 1;
      } else if (status === 4) {
        //禁用中
        this.CarmiTableParams.active_status = 0;
        this.CarmiTableParams.binding_status = 1;
      } else {
        delete this.CarmiTableParams.active_status;
        delete this.CarmiTableParams.binding_status;
      }
    },
    handleClose () {
      this.status = "";
      this.handleSelectChange("");
      this.isCarmiTableList = false;
      this.CarmiTableParams.kcode = "";
      delete this.CarmiTableParams.active_status;
      delete this.CarmiTableParams.binding_status;
    },
    handleClickXinjian () {
      this.activationData.checked = false;
      this.activationData.active_salesman = "";
      this.activationData.company = "";
      this.getMemberList().then(() => {
        this.publishOnlineDialog.display();
      });
    },
    headClass () {
      //表头居中显示
      return "text-align:center";
    },
    // 导出卡密详情
    ExportGetrecordList1 () {
      this.exportAllLoading = true;

      // 卡密管理
      if (this.currentTab === "0") {
        this.falg = 1;
        this.CarmiTableParams.provide_log_id = "";
        this.CarmiTableParams.log_id = this.rowData.id;
        this.CarmiTableParams.card_id = this.rowData.card_id;
      }

      // 在线发放
      if (this.currentTab === "1") {
        this.falg = 2;
        this.CarmiTableParams.log_id = "";
        this.CarmiTableParams.provide_log_id = this.rowData.id;
        this.CarmiTableParams.card_id = this.rowData.card_id;
      }

      const obj = {
        ...this.CarmiTableParams,
        page_no: 0,
        page_size: 0,
      };

      API_HotGoods.getcardKeysList(obj)
        .then((res) => {
          const a = document.createElement("a");
          a.href = res.data[0];
          a.download = "卡密列表" + Date.now();
          a.click();
          this.exportAllLoading = false;
        })
        .catch((_) => {
          this.exportAllLoading = false;
        });
    },
    operTypefilter (oper_type) {
      switch (oper_type) {
        case 1:
          return "禁用";
        case 2:
          return "激活";
        case 3:
          return "延期";
        default:
          return "过期";
      }
    },
    // 导出全部
    ExportGetrecordList2 () {
      this.exportAllIssueLoading = true;
      let obj = {};
      obj.card_id = this.CarmiTableParams.card_id;
      obj.log_id = this.CarmiTableParams.log_id;
      obj.provide_log_id = this.CarmiTableParams.provide_log_id;
      obj.page_no = 0;
      obj.page_size = 0;

      API_coupon.getCardsKeyOperationLog(obj)
        .then((res) => {
          this.exportAllIssueLoading = false;
          const json = {
            sheet_name: "操作日志",
            sheet_values: res.data.map((item, idx) => ({
              编号: idx + 1,
              操作时间: Foundation.unixToDate(item.create_time),
              操作类型:
                this.operTypefilter(item.oper_type) +
                (item.is_enterprise == 1
                  ? ["", "企业关卡", "企业开卡"][item.oper_type]
                  : ""),
              卡号: item.card_code,
              卡密: item.card_key,
              操作人: item.opr_name,
              ip地址: item.ip,
            })),
          };
          this.MixinExportJsonToExcel(json, "操作日志");
        })
        .catch((_) => {
          this.exportAllIssueLoading = false;
        });
    },
    // 查看操作记录
    handleClickRecord () {
      this.recordFlag = true;
      this.getrecordList();
    },
    getrecordList () {
      this.loading = true;
      let obj = {};
      obj.card_id = this.CarmiTableParams.card_id;
      obj.log_id = this.CarmiTableParams.log_id;
      obj.provide_log_id = this.CarmiTableParams.provide_log_id;
      obj.page_no = this.recordinform.page_no;
      obj.page_size = this.recordinform.page_size;
      obj.oper_type = this.recordinform.oper_type;
      obj.key_word = this.recordinform.key_word;
      API_coupon.getCardsKeyOperationLog(obj)
        .then((res) => {
          this.loading = false;
          this.recordTableData = res;
          this.recordinform.total = res.data_total;
          this.recordinform.page_no = res.page_no;
          this.recordinform.page_size = res.page_size;
        })
        .catch((_) => {
          this.loading = false;
        });
    },
    // 操作记录分页
    handlePageSizeChange1 (val) {
      this.recordinform.page_size = val;
      this.getrecordList();
    },
    // 操作记录分页
    handlePageCurrentChange1 (val) {
      this.recordinform.page_no = val;
      this.getrecordList();
    },
    getCardLogList () {
      // 卡密日志列表
      this.CarmiParams.card_id = this.id;
      this.CarmiParams.name = this.name;
      // this.CarmiTableParams
      API_HotGoods.getCardLogList(this.CarmiParams).then((res) => {
        this.CarmiTable = res.data;
        this.CarmiParams.page_no = res.page_no;
        this.CarmiParams.page_size = res.page_size;
        this.CarmiParams.total = res.data_total;
      });
    },
    handleCarmiTableSizeChange (val) {
      this.CarmiParams.page_size = val;
      this.getCardLogList();
    },
    handleCarmiTableCurrentChange (val) {
      this.CarmiParams.page_no = val;
      this.getCardLogList();
    },
    handleCarmiTableDetailrow (row) {
      this.rowData = row;
      this.cardIntervalList.card_code_start = "";
      this.cardIntervalList.card_code_end = "";
      this.handleCarmiTableDetail();
    },
    handleCarmiTableDetail (skip, status = false) {
      switch (this.CarmiTableParams.search_type) {
        case 1:
          delete this.CarmiTableParams.binding_member_mobile;
          this.CarmiTableParams.kcode = this.CarmiTableParams.search_value;
          break;
        case 2:
          delete this.CarmiTableParams.kcode;
          this.CarmiTableParams.binding_member_mobile =
            this.CarmiTableParams.search_value;
          break;
      }

      //   卡密管理   查看卡密/卡密列表      log_id 生成记录   provide_log_id 发放记录
      // btnType  判断的是哪个按钮
      //   卡密管理
      let row = this.rowData;
      if (this.currentTab === "0") {
        this.CarmiTableParams.log_id = row.id;
        this.CarmiTableParams.provide_log_id = "";
        this.falg = 1;
        this.CarmiTableParams.card_id = row.card_id;
      }

      // 在线发放
      if (this.currentTab === "1") {
        this.falg = 2;
        this.CarmiTableParams.log_id = "";
        this.CarmiTableParams.provide_log_id = row.id;
        this.CarmiTableParams.card_id = row.card_id;
      }

      if (!skip) {
        this.CarmiTableParams.page_no = 1;
      }

      delete this.CarmiTableParams.total;

      if (
        status &&
        (this.cardIntervalList.card_code_start ||
          this.cardIntervalList.card_code_end)
      ) {
        this.CarmiTableParams.card_code_start =
          this.cardIntervalList.card_code_start;
        this.CarmiTableParams.card_code_end =
          this.cardIntervalList.card_code_end;
      } else if (
        this.CarmiTableParams.card_code_start ||
        this.CarmiTableParams.card_code_end
      ) {
        delete this.CarmiTableParams.card_code_start;
        delete this.CarmiTableParams.card_code_end;
      }

      API_HotGoods.getcardKeysList(this.CarmiTableParams).then((res) => {
        if (res.data.length === 0 && res.page_no !== 1) {
          this.CarmiTableParams.page_no -= 1;
          this.handleCarmiTableDetail(true);
        }
        res.data.forEach((item) => {
          item["video_url"] = item["video_url"]
            ? item["video_url"].replace("amp;", "")
            : "";
        });
        this.CarmiTableList = res;
        this.viewCardDialogRef.display();
        this.$nextTick(() => {
          this.$refs.viewCardTable.clearSelected();
        });
      });
    },
    handleCarmiTableListSizeChange (val) {
      this.CarmiTableParams.page_size = val;
      this.handleCarmiTableDetail(true, true);
    },
    handleCarmiTableListCurrentChange (val) {
      this.CarmiTableParams.page_no = val;
      this.handleCarmiTableDetail(true, true);
    },

    resetForm (formName) {
      this.jihuoFromDataFlag = false;
      this.$refs[formName].resetFields();
    },
    // 激活表单确定
    handlejihuoFormData (formName) {
      if (this.jihuoFromDataAll) {
        // 操作全部
        API_HotGoods.updateCardsKeyStatus({
          operate_all: 1,
          ids: [0],
          card_id: this.id,
          active_status: 1,
          active_salesman: this.jihuoFromData.active_salesman,
          company: this.jihuoFromData.company,
          log_id: this.CarmiTableParams.log_id,
          provide_log_id: this.CarmiTableParams.provide_log_id,
          count: this.CarmiTableParams.total,
        }).then((res) => {
          // this.selectCarmiTable = [];
          this.$message({
            type: "success",
            message: "全部激活成功",
          });
          this.handleCarmiTableDetail(true);
          this.jihuoFromDataFlag = false;
          this.$refs[formName].resetFields();
        });
      } else {
        // 激活的是选中的数据
        this.$refs[formName].validate((valid) => {
          if (valid) {
            // 是否为激活选中
            if (this.jihuoFromDataRowVla) {
              let arr2 = [];
              this.selectCarmiTable.map((item) => {
                arr2.push(item.id);
              });
              API_HotGoods.updateCardsKeyStatus({
                operate_all: 0,
                ids: arr2,
                card_id: this.id,
                active_status: 1,
                active_salesman: this.jihuoFromData.active_salesman,
                company: this.jihuoFromData.company,
              }).then((res) => {
                // this.selectCarmiTable = [];
                this.$message({
                  type: "success",
                  message: "选中项激活成功",
                });
                this.handleCarmiTableDetail(true);
                this.jihuoFromDataFlag = false;
                this.$refs[formName].resetFields();
              });
            } else {
              // 激活当前
              let arr = [];
              arr.push(this.jihuoFromRow.id);
              // jihuoFromData.active_salesman  company
              API_HotGoods.updateCardsKeyStatus({
                operate_all: 0,
                ids: arr,
                card_id: this.id,
                active_status: 1,
                active_salesman: this.jihuoFromData.active_salesman,
                company: this.jihuoFromData.company,
              }).then((res) => {
                this.$message({
                  type: "success",
                  message: "激活成功",
                });
                // this.handleCarmiTableDetailrow(this.jihuoFromRow);
                this.handleCarmiTableDetail(true);
                this.jihuoFromDataFlag = false;
                this.$refs[formName].resetFields();
              });
            }
          } else {
            return false;
          }
        });
      }
    },
    handleCarmiTableListActivation (row, num) {
      if (num === "postpone")
        return (this.jihuoFromRow = row), this.postpone.display(); // 延期
      if (num === 0) {
        // 激活操作
        this.jihuoFromRow = row;
        this.jihuoFromnum = num;
        this.jihuoFromData.active_salesman = row.active_salesman; // 操作业务员
        this.jihuoFromData.company = row.company; // 所属销售公司
        this.fillInActivationInformation.display();
        this.jihuoFromDataAll = false;
      } else {
        // 禁用当前
        let arr = [];
        arr.push(row.id);
        API_HotGoods.updateCardsKeyStatus({
          operate_all: 0,
          ids: arr,
          card_id: this.id,
          active_status: 0,
        }).then((res) => {
          this.$message({
            type: "success",
            message: "禁用成功",
          });
          this.handleCarmiTableDetail(row);
        });
      }
    },
    CarmiTableListSelectChange (val) {
      this.selectCarmiTable = val;
    },
    handleActivationCheck (num) {
      // 批量激活
      let arr = [];
      if (
        this.selectCarmiTable &&
        this.selectCarmiTable.length &&
        this.selectCarmiTable.length > 0
      ) {
        this.selectCarmiTable.map((item) => {
          arr.push(item.id);
        });
      } else {
        this.$message.error("请选中数据！");
        return;
      }
      this.jihuoFromDataRowVla = false;
      if (num === 0) {
        // 激活需要填写业务员
        this.fillInActivationInformation.display();
        // 点击的不是操作全部
        this.jihuoFromDataAll = false;
        // 记录是否为激活选中数据
        this.jihuoFromDataRowVla = true;
      } else if (num == 3) { //批量延期
        this.postpone.display();
      } else {
        API_HotGoods.updateCardsKeyStatus({
          operate_all: 0,
          ids: arr,
          card_id: this.id,
          active_status: num === 0 ? 1 : 0,
        }).then((res) => {
          // this.selectCarmiTable = [];
          this.$message({
            type: "success",
            message: num === 0 ? "选中项激活成功" : "选中项禁用成功",
          });
          this.handleCarmiTableDetail(true);
        });
      }
    },
    handleActivationAll (num) {
      // 记录点的是激活全部按钮
      this.jihuoFromDataAll = true;

      if (num === 0) {
        // 激活需要填写业务员
        this.fillInActivationInformation.display();
      } else {
        // 操作全部
        this.cancelActivatedAllLoading = true;
        API_HotGoods.updateCardsKeyStatus({
          operate_all: 1,
          ids: [0],
          card_id: this.id,
          active_status: num === 0 ? 1 : 0,
          log_id: this.CarmiTableParams.log_id,
          provide_log_id: this.CarmiTableParams.provide_log_id,
          count: this.CarmiTableParams.total,
        })
          .then((res) => {
            // this.selectCarmiTable = [];
            this.cancelActivatedAllLoading = false;
            this.$message({
              type: "success",
              message: num === 0 ? "全部激活成功" : "全部禁用成功",
            });
            this.handleCarmiTableDetail(true);
          })
          .catch(() => {
            this.cancelActivatedAllLoading = false;
          });
      }
    },
    // 延期操作
    postponeFn () {
      let flag = true
      this.$refs.postpone.validate(valid => {
        if (valid) {
          let arr = []
          if (this.selectCarmiTable && this.selectCarmiTable.length > 0) {
            this.selectCarmiTable.map((item) => {
              arr.push(item.id);
            });
          }
          API_Card.expCardsKey({
            ids: this.jihuoFromRow.id ? this.jihuoFromRow.id : arr.toString(),
            day: this.postponeVal.num,
          })
            .then(res => {
              this.postponeLoading = false;
              this.$message({
                type: "success",
                message: "延期成功"
              });
              this.getLookList(); // 查看
              this.$refs["postpone"].resetFields(); // 重置表单
            })
            .catch(() => {
              this.postponeLoading = false;
            });
        } else {
          return flag = false;
        }
      });
      return flag
    },
    submitCreateCardKeys () {
      //系统生成卡密
      this.submitCreateCardKeysParams.card_id = this.id;
      return API_HotGoods.submitCreateCardKeys(
        this.submitCreateCardKeysParams
      ).then((res) => {
        this.$message({
          type: "success",
          message: "卡密生成成功",
        });
        this.createCarmi = false;
        this.getCardLogList();
      });
    },
    downTemplate () {
      // 下载模板
      let tHeaders = [],
        filterVals = [];
      if (this.downLoadType === 0) {
        tHeaders = ["卡密"];
        filterVals = ["yyyy"];
      }

      this.MixinSetShopExtAuthList().then((res) => {
        if (this.downLoadType === 1) {
          if (res.toHiddenAuth.includes("shareVideoList")) {
            tHeaders = ["卡号", "卡密"];
          } else {
            tHeaders = ["卡号", "卡密", "卡号前缀"];
          }
          filterVals = ["xxxx", "yyyy"];
        }
        selfDefinedTemplate(
          this.integralImportData,
          tHeaders,
          filterVals,
          "卡密导入模板"
        );
      });
    },
    async excelSuccess ({ results, shop_name, card_name }) {
      console.log(results);
      this.submitImportCardKeysParams.cards_key_dolist = [];
      this.submitImportCardKeysParams.shop_name = shop_name;
      this.submitImportCardKeysParams.card_name = card_name;
      console.log(results, this.downLoadType);
      results.map((item, index) => {
        if (this.downLoadType === 0) {
          this.submitImportCardKeysParams.cards_key_dolist.push({
            card_key: item["卡密"],
          });
        } else {
          this.submitImportCardKeysParams.cards_key_dolist.push({
            card_code: item["卡号"],
            card_key: item["卡密"],
          });
        }
      });
      console.log(this.submitImportCardKeysParams.cards_key_dolist);
      // var flag, flag1;
      // if (this.downLoadType == 1) {
      //   // 卡号+卡密
      //   flag = await this.uniqueArrObj(
      //     this.submitImportCardKeysParams.cards_key_dolist,
      //     "card_code"
      //   );
      //   flag1 = await this.uniqueArrObj(
      //     this.submitImportCardKeysParams.cards_key_dolist,
      //     "card_key"
      //   );
      // } else {
      //   // 仅卡密
      //   flag1 = await this.uniqueArrObj(
      //     this.submitImportCardKeysParams.cards_key_dolist,
      //     "card_key"
      //   );
      // }
    },
    //数组中根据对象唯一标识对象去重
    //参数1: 是一个数组,数组中是对象
    //参数2: 每一个对象中的唯一标识
    uniqueArrObj (arrhas, unique) {
      let arr = [];
      let arred = [];
      let data = this.MixinClone(arrhas);
      data.map((item) => {
        arr.push(item[unique]);
        arred.push(item[unique]);
        if (unique === "card_code") {
          if (item[unique].length < 6 || item[unique].length > 16) {
            return false;
          }
        }
        if (unique === "card_key") {
          if (item[unique].length < 8 || item[unique].length > 20) {
            return false;
          }
        }
      });
      arr = [...new Set(arr)];
      return arr.length === arred.length;
    },
    submitImportCardKeys () {
      console.log(this.submitImportCardKeysParams, this.downLoadType);
      if (
        !this.submitImportCardKeysParams.cards_key_dolist[0] ||
        (this.downLoadType &&
          !this.submitImportCardKeysParams.cards_key_dolist[0].card_code) ||
        (!this.downLoadType &&
          !this.submitImportCardKeysParams.cards_key_dolist[0].card_key)
      ) {
        this.$message.error("导入的数据模板有误，请重新导入");
        return;
      }

      // 导入表格生成卡密
      this.submitImportCardKeysParams.card_id = this.id;
      this.submitImportCardKeysParams.prefix = this.downLoadType;
      return API_HotGoods.submitImportCardKeys(this.submitImportCardKeysParams)
        .then((res) => {
          let alert_content = `本次表格导入数据共${res.false + res.success
            }条, 其中成功${res.success}条, 失败${res.false}条`;
          if (res.false > 0) {
            this.$alert(alert_content, "提示", {
              confirmButtonText: "下载失败数据",
            })
              .then(() => {
                window.location.href = res.file;
              })
              .catch(() => { });
          } else {
            this.$alert(alert_content, "提示")
              .then(() => { })
              .catch(() => { });
          }
          this.createCarmi = false;
          this.getCardLogList();
        })
        .catch(() => {
          this.createCarmi = false;
          this.getCardLogList();
        });
    },

    // 在线发放列表
    getProvideLog () {
      this.grantListParams.card_id = this.id;
      this.grantListParams.name = this.name;
      API_HotGoods.getProvideLog(this.grantListParams).then((res) => {
        // this.grantListParams.page_no = res.data_page_no;
        // this.grantListParams.page_size = res.data_page_size;
        // this.grantListParams.total = res.data_total;
        this.grantList = res;
      });
    },
    handlegrantListSizeChange (val) {
      this.grantListParams.page_size = val;
      this.getProvideLog();
    },
    handlegrantListCurrentChange (val) {
      this.grantListParams.page_no = val;
      this.getProvideLog();
    },
    getMemberList () {
      let obj = this.MixinClone(this.createGrantParams);
      if (!obj.group_id) {
        obj.group_id = 0;
      }

      return API_HotGoods.getMemberList(obj).then((res) => {
        // this.createGrantParams.page_no = res.page_no;
        // this.createGrantParams.page_size = res.page_size;
        // this.createGrantParams.total = res.data_total;
        this.createGranList = res;
      });
    },
    handlecreateGrantSizeChange (val) {
      this.createGrantParams.page_size = val;
      this.getMemberList();
    },
    handlecreateGrantCurrentChange (val) {
      this.createGrantParams.page_no = val;
      this.getMemberList();
    },
    createGrantTableSelectChange (val) {
      this.createGranSelect = JSON.parse(JSON.stringify(val));
    },
    getUserlist () {
      let info = {
        page_size: 0,
        page_no: 0,
      };
      API_HotGoods.getUserlist(info).then((res) => {
        this.UserOptions = res.data;
      });
    },
    changeUserList (e) {
      if (!e) {
        this.submitCreateGrantParams.group_id = 0;
      } else {
        this.submitCreateGrantParams.group_id = e;
      }
      this.$refs.multipleTable.clearSelection(); // 每次变换清空一次数据
      this.createGrantParams.page_no = 1;
      this.getMemberList();
    },
    submitCreateGrant (formName) {
      let status = true;
      if (this.activationData.checked) {
        this.$refs[formName].validate((valid) => {
          status = valid;
        });
      }
      if (status) {
        // 发放给系统用户提交
        if (this.createGranSelect.length === 0) {
          this.$message.error("请选择发放的用户");
          return;
        }
        this.submitCreateGrantParams.card_id = this.id;
        this.submitCreateGrantParams.member_ids = [];
        this.submitCreateGrantParams.member_names = [];
        this.submitCreateGrantParams.phones = [];
        this.submitCreateGrantParams.active_status = 0;
        // 是否激活
        if (this.activationData.checked) {
          // 激活
          this.submitCreateGrantParams.active_status = 1;
          this.submitCreateGrantParams.active_salesman =
            this.activationData.active_salesman;
          this.submitCreateGrantParams.company = this.activationData.company;
        } else if (this.submitCreateGrantParams.active_salesman) {
          // 没激活删除对应参数
          delete this.submitCreateGrantParams.active_salesman;
          delete this.submitCreateGrantParams.company;
        }
        this.createGranSelect.forEach((item) => {
          this.submitCreateGrantParams.member_ids.push(item.member_id);
          this.submitCreateGrantParams.member_names.push(item.nickname);
          this.submitCreateGrantParams.phones.push(item.mobile);
        });
        if (!this.submitCreateGrantParams.group_id) {
          this.submitCreateGrantParams.group_id = this.createGranSelect[0]
            .group_id
            ? this.createGranSelect[0].group_id
            : 0;
        }
        return API_HotGoods.bindingMembers(this.submitCreateGrantParams).then(
          (res) => {
            this.$message({
              title: "提示",
              message: "成功发放",
              type: "success",
            });
            this.createGrantDielog = false;
            this.$refs.multipleTable.clearSelection();
            this.checked = false;
            this.submitCreateGrantParams.send_message = 0;
            this.getProvideLog();
          }
        );
      } else this.$message.error("业务员名称与所属销售公司填写有误");
    },
    downTemplate1 () {
      // 下载模板进行编辑
      let tHeaders = ["分组名称", "手机号", "姓名"];
      selfDefinedTemplate(this.integralImportData, tHeaders);
    },
    excelSuccess1 ({ results, shop_name, card_name }) {
      let templateHedaer = ["分组名称", "手机号", "姓名"];
      let templateKey = ["group_name", "mobile", "nickname"];
      let resultsList = [];
      console.log(results);
      results.forEach((list, idx) => {
        templateKey.forEach((item, index) => {
          list = JSON.parse(
            JSON.stringify(list).replace(templateHedaer[index], item)
          );
          // if (!list[item]) {
          //   this.$message.error("信息不完整");
          // }
        });
        // if (!RegExp.mobile.test(list.mobile)){
        // 	list.errorText = '手机号格式非法'
        // 	this.isError = true
        // }
        resultsList.push(list);
      });
      this.cardMemberVO.card_id = this.id;
      this.cardMemberVO.shop_name = shop_name;
      this.cardMemberVO.card_name = card_name;
      this.cardMemberVO.member_list = resultsList;
    },
    submitCreateGrant1 (formName) {
      let status = true;
      if (this.activationData.checked) {
        this.$refs[formName].validate((valid) => {
          status = valid;
        });
      }
      if (status) {
        if (!this.cardMemberVO.card_id) {
          this.$message.error("请上传数据！");
          return;
        }
        this.cardMemberVO.active_status = 0;
        // 是否激活
        if (this.activationData.checked) {
          // 激活
          this.cardMemberVO.active_status = 1;
          this.cardMemberVO.active_salesman =
            this.activationData.active_salesman;
          this.cardMemberVO.company = this.activationData.company;
        } else if (this.cardMemberVO.active_salesman) {
          // 没激活删除对应参数
          delete this.cardMemberVO.active_salesman;
          delete this.cardMemberVO.company;
        }
        return API_HotGoods.importBindingMembers(this.cardMemberVO).then(
          (res) => {
            let alert_content = `本次表格导入数据共${res.false + res.success
              }条, 其中成功${res.success}条, 失败${res.false}条`;
            if (res.false > 0) {
              this.$alert(alert_content, "提示", {
                confirmButtonText: "下载失败数据",
              })
                .then(() => {
                  window.location.href = res.file;
                })
                .catch(() => {
                  console.log("关闭窗口");
                });
            } else {
              this.$alert(alert_content, "提示")
                .then(() => { })
                .catch(() => { });
            }
            this.cardMemberVO.member_list = {};
            this.cardMemberVO.card_id = "";
            this.createGrantDielog = false;
            this.checked = false;
            this.submitCreateGrantParams.send_message = 0;
            this.getProvideLog();
          }
        );
      } else this.$message.error("业务员名称与所属销售公司填写有误");
    },
    /**
     * 初始化区间激活/禁用填写弹窗
     *  @param type
     */
    intervalToActivate (type) {
      this.intervalType = type;
      this.intervalFromData.active_salesman = "";
      this.intervalFromData.company = "";
      this.intervalFromData.cardList = [
        {
          card_code_start: "",
          card_code_end: "",
          dialogPromptText: "",
        },
      ];
      type
        ? this.intervalActivationInformation.display()
        : this.intervalDisable.display();
    },
    /**
     * 区间激活处理
     * @param formName
     */
    handleIntervalActivation (formName) {
      this.$refs[formName].validate((valid) => {
        let validTwo = valid;
        let cards_scope = [];
        if (valid) {
          this.intervalFromData.cardList.forEach((item, index) => {
            this.checkHandle(item.card_code_start, item.card_code_end, index);
            if (!item.dialogPromptText) {
              if (item.card_code_start && item.card_code_end)
                cards_scope.push(
                  item.card_code_start + ";" + item.card_code_end
                );
            } else validTwo = false;
          });

          if (validTwo) {
            API_HotGoods.updateCardsKeyStatus({
              operate_all: 0,
              card_id: this.id,
              log_id: this.rowData.id,
              active_status: this.intervalType,
              active_salesman: this.intervalFromData.active_salesman,
              company: this.intervalFromData.company,
              cards_scope,
            }).then((res) => {
              this.$message.success("区间激活成功");
              this.handleCarmiTableDetail(true);
              this.intervalActivationInformation.visible = false;
            });
          } else this.$message.error("格式错误：请根据提示填写卡号区间");
        } else this.$message.error("业务员名称与所属销售公司填写有误");
      });
      return false;
    },
    /**
     * 区间禁用处理
     */
    handleIntervalDisable () {
      let validTwo = true;
      let cards_scope = [];
      this.intervalFromData.cardList.forEach((item, index) => {
        this.checkHandle(item.card_code_start, item.card_code_end, index);
        if (!item.dialogPromptText) {
          if (item.card_code_start && item.card_code_end)
            cards_scope.push(item.card_code_start + ";" + item.card_code_end);
        } else validTwo = false;
      });
      if (validTwo) {
        API_HotGoods.updateCardsKeyStatus({
          operate_all: 0,
          card_id: this.id,
          log_id: this.rowData.id,
          active_status: this.intervalType,
          cards_scope,
        }).then((res) => {
          this.$message.success("区间禁用成功");
          this.handleCarmiTableDetail(true);
          this.intervalDisable.visible = false;
        });
        return false;
      } else {
        this.$message.error("格式错误：请根据提示填写卡号区间");
        return false;
      }
    },
  },
};
</script>

<style lang="scss">
.cash-coupon-manager-main-dialog {
  .el-tabs {
    height: 80vh;

    .el-tabs__header {
      margin-bottom: 0;
    }

    .el-tab-pane {
      height: calc(80vh - 48px);
    }
  }
}

.view-card-dialog {
  .el-scrollbar {
    height: 70vh;
  }

  .el-scrollbar__view {
    padding: 0 !important;
  }

  .el-table__body-wrapper {
    overflow-x: hidden;
  }
}

.addCardPasswordDialog {
  .el-scrollbar__view {
    padding: 0 !important;
  }

  .el-tabs--card>.el-tabs__header {
    margin-bottom: 0;
  }

  .el-tabs__content {
    padding: 16px;
  }
}

.add-camilo-file-box {
  white-space: nowrap;

  >.add-camilo-file {
    padding: 8px;
    background-color: rgba(231, 228, 228, 0.6);
    border-radius: 4px;
    border: none;
  }
}

.dialog-card-code {
  margin-top: 4px;
  position: absolute;
  right: 0;
}

.card-code-prompt-text {
  position: absolute;
  margin-top: -8px;
  font-size: 12px;
  color: red;
  z-index: 99;
}

.tooltip-text-32 {
  display: inline-block;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
</style>
