<template>
  <div class="promotion-manage-cash-coupon-list">
    <en-table-layout :tableData="pageData.data" @selection-change="handleSelectionChange2">
      <template slot="toolbar">
        <div class="col-auto">
          <el-form :inline="true" :model="params" class="demo-form-inline">
            <el-form-item label="状态">
              <el-select size="medium" v-model="params.status" style="width: 100px">
                <el-option label="全部" value=""></el-option>
                <el-option label="启用中" value="1"></el-option>
                <el-option label="禁用中" value="0"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="关键词">
              <el-input size="medium" v-model="params.keyword" placeholder="请输入卡券名称/面值进行搜索" clearable
                style="width: 250px" />
            </el-form-item>
          </el-form>
        </div>
        <el-button type="primary" size="small" @click="searchCard">搜索</el-button>
        <div class="col"></div>
        <el-button type="primary" size="small" @click="handleAddCashCoupon">添加储值卡</el-button>
      </template>

      <template slot="table-columns">
        <el-table-column label="编号" width="50" fixed="left">
          <template slot-scope="scope">{{ scope.$index + 1 }}</template>
        </el-table-column>

        <el-table-column label="卡券名称" min-width="190" show-overflow-tooltip fixed="left">
          <template slot-scope="scope">{{ scope.row.card_name }}</template>
        </el-table-column>

        <el-table-column label="图片" width="140" fixed="left">
          <template slot-scope="scope" v-if="scope.row.logo_url">
            <img :src="scope.row.logo_url" style="width:117px;height:67px" />
          </template>
        </el-table-column>

        <el-table-column label="面值" min-width="110">
          <template slot-scope="scope">
            {{ scope.row.card_value | unitPrice() }}
          </template>
        </el-table-column>

        <el-table-column label="有效时间" width="170">
          <template slot-scope="scope">
            {{ scope.row.start_time | unixToDate('yyyy-MM-dd') }}~{{ scope.row.end_time | unixToDate('yyyy-MM-dd') }}
          </template>
        </el-table-column>

        <el-table-column label="总数" width="80">
          <template slot-scope="scope">
            {{ scope.row.key_num }}
          </template>
        </el-table-column>
        <el-table-column label="已激活数" width="80">
          <template slot-scope="scope">
            {{ scope.row.active_num }}
          </template>
        </el-table-column>
        <el-table-column label="激活总金额" width="110">
          <template slot-scope="scope">
            {{ (scope.row.card_value * scope.row.active_num) | unitPrice('￥') }}
          </template>
        </el-table-column>
        <el-table-column label="已绑定数" width="80">
          <template slot-scope="scope">
            {{ scope.row.binding_num }}
          </template>
        </el-table-column>
        <el-table-column label="状态" width="63">
          <template slot-scope="scope">
            {{ scope.row.status === 1 ? '启用中' : '禁用中' }}
          </template>
        </el-table-column>

        <el-table-column label="操作" width="210" fixed="right">
          <template slot-scope="scope">
            <el-button size="mini" type="primary" @click="distribution(scope.$index, scope.row)">管理</el-button>
            <!-- <el-button
              size="mini"
              type="primary"
              @click="gotwo(scope.row.id)"
              >生成</el-button
            > -->
            <!-- <el-button size="mini" @click="goDhm(scope.$index, scope.row)"
              >兑换码</el-button
            > -->
            <el-button size="mini" type="danger" @click="handleEdit(scope.$index, scope.row)">编辑</el-button>
            <!-- <el-button
              size="mini"
              type="danger"
              @click="handleDelete(scope.$index, scope.row)"
            >删除</el-button> -->
            <el-button size="mini" :type="scope.row.status === 1 ? 'danger' : 'primary'"
              @click="handleDeleteStatus(scope.row)">
              {{ scope.row.status === 1 ? '禁用' : '启用' }}
            </el-button>
          </template>
        </el-table-column>
      </template>

      <el-pagination slot="pagination" @size-change="handleSizeChange" @current-change="handleCurrentChange"
        :current-page.sync="currentPage3" :page-size="pageData.page_size" :page-sizes="MixinPageSizes"
        :layout="MixinTableLayout" background :total="pageData.data_total" />
    </en-table-layout>

    <grant ref="grant"></grant>
  </div>
</template>

<script>
import * as API_coupon from '@/api/coupon';
import grant from './components/djjgrant';
import EnTableLayout from '../../../../ui-components/TableLayout/src/main';

export default {
  components: {
    EnTableLayout,
    grant,
  },
  data () {
    return {
      total: 0,
      flag: true,
      distributionflag: false,
      params: {
        page_no: 1,
        page_size: 20,
        mall_type: '',
        shopDisable: '',
        keyword: '',
        card_type: 2,
        card_name: '',
        status: '',
      },
      currentPage3: 1,
      selectedData: [],
      pageData: { data: [] },
      search: '',
      czkmsg: 0,
      formInline: {
        keyword: '',
        page_no: 1,
        page_size: 20,
        status: '',
      },
    };
  },
  watch: {
    $route: function () {
      if (this.$route.name === 'couponList') {
        //   优惠券
        this.params.card_type = 2;
        this.flag = true;
        this.getyhj();
        // console.log(this.params.card_type, 'paramsparams');
      } else if (this.$route.name === 'cashCouponList') {
        //   代金券
        this.params.card_type = 3;
        this.flag = false;
        this.getyhj();
        // console.log(this.params.card_type, 'paramsparams');
      } else {
        //   充值卡
        this.params.card_type = 4;
        this.flag = false;
        this.getyhj();
        // console.log(this.params.card_type, 'paramsparams');
      }
    },
  },
  created () {
    // console.log(this.$route.name, 'namename');
    if (this.$route.name === 'couponList') {
      this.flag = true;
      this.params.card_type = 2;
      this.getyhj();
    } else if (this.$route.name === 'czkMealList') {
      // console.log('我在4');
      this.flag = false;
      this.params.card_type = 4;
      this.getyhj();
    } else {
      this.flag = false;
      this.params.card_type = 3;
      this.getyhj();
    }
    this.getyhj();
  },
  methods: {
    /** 添加储值卡（代金券） */
    handleAddCashCoupon () {
      this.$router.push({ path: '/tools/promotion-manage/cash-coupon-edit' });
    },
    /** 编辑储值卡（代金券） */
    handleEdit (index, row) {
      if (row.card_type === 3) {
        this.$router.push({
          path: '/tools/promotion-manage/cash-coupon-edit',
          query: { id: row.id }
        });
      } else if (row.card_type === 2) {
        this.$router.push({
          path: '/tools/promotion-manage/coupon-edit',
          query: { id: row.id }
        });
      } else {
        this.$router.push({
          path: '/application/czkadd',
          query: { id: row.id }
        });
      }
    },
    distribution (index, row) {
      this.$refs.grant.show(row);
    },
    goDhm (index, row) {
      if (this.$route.name === 'czkMealList') {
        this.$router.push({
          path: '/application/exchangeCode1',
          query: { card_id: row.id },
        });
      } else {
        this.$router.push({
          path: '/tools/promotion-manage/exchangeCode',
          query: { card_id: row.id },
        });
      }

      // console.log(index, row, 'look see see');
    },
    gotwo (id) {
      if (this.$route.name === 'cashCouponList') {
        //   代金券
        this.$router.push({
          path: '/promotion/djjadd',
          query: {
            active: 2,
            card_id: id
          },
        });
      } else if (this.$route.name === 'czkMealList') {
        //   充值卡
        this.$router.push({
          path: '/application/czkadd',
          query: {
            active: 2,
            card_id: id
          },
        });
      } else {
        //   优惠券
        this.$router.push({
          path: '/tools/promotion-manage/cash-coupon-edit',
          query: {
            active: 2,
            card_id: id
          },
        });
      }
    },
    searchCard () {
      this.formInline.card_type = this.params.card_type;
      API_coupon.getDhmlist(this.params).then((res) => {
        this.pageData = res;
      });
    },
    getyhj () {
      let params = this.params;
      API_coupon.getDhmlist(params).then((res) => {
        this.pageData = res;
        // console.log(res, '新的resresresresresresresres');
      });
    },
    handleSelectionChange2 (val) {
      this.selectedData = val;
    },
    handleSizeChange (val) {
      this.params.page_size = val;
      this.getyhj();
      // console.log(`每页 ${val} 条`);
    },
    handleCurrentChange (val) {
      this.currentPage3 = val;
      this.params.page_no = val;
      this.getyhj();
      // console.log(`当前页: ${val}`);
    },
    handleDeleteStatus (row) {
      let {
        id,
        status
      } = row;
      // updateStatus
      this.$confirm(
        `确定${status === 1 ? '禁用' : '启用'}此卡券，是否继续?`,
        '提示',
        { type: 'warning' }
      )
        .then(() => {
          API_coupon.updateStatus({
            id: id,
            status: status === 1 ? 0 : 1,
          }).then((res) => {
            this.getyhj();
            this.$message.success('操作成功！');
          });
        })
        .catch(() => {
        });
    },
    handleDelete (index, row) {
      let id = [row.id];
      this.$confirm(`确定删除此卡券，是否继续?`, '提示', { type: 'warning' })
        .then(() => {
          API_coupon.cardDelete({ ids: id }).then((res) => {
            this.getyhj();
            // console.log(res, '删除成功');
          });
        })
        .catch(() => {
        });
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .en-table-layout .el-table .el-table__fixed {
  bottom: 0;
}

::v-deep .en-table-layout .el-table .el-table__fixed-right {
  bottom: 0;
}
</style>
